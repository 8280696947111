import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/seo';
import '../scss/custom.scss';
import Layout from '../components/layout';
import FeatureArticle from '../components/cards/featureArticle';

const Page = ({ data, pageContext }) => {

  const { edges } = data.allMdx;
  const { tag } = pageContext;
  const title = `All ${tag} articles`;
  const description = `All ${tag} articles`;


  return (
    <Layout>
      <Seo title={title} description={description} />
        <h1>รวมบทความเกี่ยวกับ <em>{tag}</em></h1>
        {edges.map((article) => {
          const { slug, frontmatter } = article.node;
          return (
            <FeatureArticle slug={slug} frontmatter={frontmatter} />
          );
        })}
    </Layout>
  );
};


export default Page;

export const query = graphql`
  query ($tag: String) {
     allMdx(filter: {frontmatter: {tags: {eq: $tag}}}) {
       edges {
         node {
           frontmatter {
             title
             date(formatString: "MMMM Do, YYYY")
             description
             tags
             category
             author
             heroImage {
               childImageSharp {
                 gatsbyImageData(layout: FULL_WIDTH)
               }
             }
           }
           slug
         }
       }
     }
  }
`;
